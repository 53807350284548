import React, { Component } from 'react';

class Tokens extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: props.users
        };
    }

    render() {
        return <div>
            <p>Токены начисляются автоматически нашим ботом-бухгалтером.</p>
            <br/>
            <p>Система начисления токенов:</p>
            <p>
                1. За нахождение в голосовом канале с включённым микрофоном вы получаете от <b>0</b> до <b>2</b> токенов каждые 5 минут
                <br/>
                2. За каждое сообщение, длиннее 3х знаков и не являющаяся флудом/спамом вы получаете от <b>4</b> до <b>7</b> токенов
                <br/>
                3. За каждую картинку-мем смешнее «Привет, Медведь» вы получаете от <b>5</b> до <b>10</b> токенов
            </p>
        </div>
    }
}

export default Tokens;